import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import jwt from 'jwt-decode'
import DocTypes from '../doc-types/docTypes'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import {environmentLabel} from '../config'

const DocumentTypes = () => {
    const history = useHistory()
    const { authState,  authService } = useOktaAuth()

    const [ types, setTypes ] = useState([])
    const [ businessIDs, setBusinessIDs ] = useState([])
    const [ filter, setFilter ] = useState([])
    const [ filterLabel, setFilterLabel ] = useState("")
    const [ filterSlug, setFilterSlug ] = useState("")
    const [ filterBusinessID, setFilterBusinessID ] = useState("")
    const [ showFilter, setShowFilter ] = useState(false)
    const [ filterApplied, setFilterApplied ] = useState(false)
    const [ showCreate, setShowCreate ] = useState(false)
    const [ createDocType, setCreateDocType ] = useState({
        business_id: "",
        label: "",
        slug: ""
    })
    const [ showUpdate, setShowUpdate ] = useState(false)
    const [ updateDocType, setUpdateDocType ] = useState({
        id: 0,
        business_id: "",
        label: "",
        slug: ""
    })
    const [ display, setDisplay ] = useState({
        allRows: [],
        rows: [],
        size: 10,
        show: false, 
        nav: false,
        total: -1,
        curr: -1,
    })
    const [ showRemove, setShowRemove ] = useState(false)
    const [ removeDocType, setRemoveDocType ] = useState(-1)
    const [ envLabel, setEnvLabel ] = useState("N/A")

    const back = async () => {
        history.push('./')
    }

    useEffect(() => {
        if (authState.isAuthenticated == false) {
            alert("User must be authenticated")
            return 
        }

        try {
            let body = jwt(authState.accessToken) 
            console.log(body)
            
            let dt = new DocTypes(authState.accessToken)
            dt.list(body.bsn)
            .then(resp => {
                setTypes(resp)
                setFilter(resp)
                initDisplay(resp)
                setBusinessIDs(body.bsn)
                console.log(resp)
            })
            .catch(err => {
                console.log(err)
                alert(err)

            })
            setEnvLabel(environmentLabel())
        }catch(err) {
            console.log(err)
            alert(err)
        }
    }, [])

    const handleNavSelect = (evt) => {
        switch(evt){
        case "home":
            back()
            return 
        case "filter":
            setShowFilter(true)
            return
        case "reset":
            resetFilter()
            return
        default:
            alert(evt)
        }
    }

    const initDisplay = (filter) => {
        let d = {
            allRows: filter,
            rows: [],
            size: 10,
            show: false, 
            total: filter.length,
            curr: 1,
            nav: false
        }

        if (filter.length > d.size) {
            d.rows = filter.slice(0, d.size)
            d.show = true 
            d.nav = true
        } else {
            d.rows = filter
        }
        console.log(filter)
        console.log(d)
        setDisplay(d)
    }

    const handleDisplayNav = (evt) => {
        if (display.nav == false) {
            return 
        }

        displayNav(evt.target.name)
    }

    const displayNav = (action) => {

        let next = 0
        let end = 0

        switch (action) {
        case "start":
            display.rows = display.allRows.slice(0, display.size)
            display.curr = 1
            break
        case "next":
            if((display.curr * display.size) >= display.total) {
                return
            }
            next = display.curr * display.size 
            end = next + display.size 
            if (end > display.total) {
                end = display.total
            }
            display.rows = display.allRows.slice(next, end)
            display.curr = display.curr + 1
            if((display.curr * display.size) >= display.total) {
                display.curr -=1
            }
            break
        case "prev":
            if(display.curr == 1) {
                return
            }
            display.curr -= 1
            next = (display.curr - 1) * display.size 
            end = next + display.size 
            if (end > display.total) {
                end = display.total
            }
            display.rows = display.allRows.slice(next, end)
            break
        case "last":
            next = Math.floor(display.total / display.size)
            if((display.total % display.size) == 0) {
                next--   
            }
            display.curr = next 
            next = display.curr * display.size 
            end = next + display.size 
            if (end > display.total) {
                end = display.total
            }
            display.rows = display.allRows.slice(next, end)
            break
        default:
            alert(action)
            return
        }
        setDisplay({...display})
    }

    const labelChange = (evt) => {
        setFilterLabel(evt.target.value)
    }

    const slugChange = (evt) => {
        setFilterSlug(evt.target.value)
    }

    const businessIDChange = (evt) => {
        setFilterBusinessID(evt.target.value)
    }

    const applyFilter = () => {
        let f = []
        console.log(filterBusinessID)
        console.log(filterLabel)
        console.log(filterSlug)
        types.forEach(t => {
            console.log(t)
            if (filterBusinessID != t.business_id) {
                return
            }
            if (filterLabel.length > 0 && t.label.startsWith(filterLabel) == false) {
                return
            }
            if (filterSlug.length > 0 && t.slug.startsWith(filterSlug) == false) {
                return
            }
            f.push(t)
        })
        setFilter(f)
        initDisplay(f)
        setShowFilter(false)   
        setFilterApplied(true)
    }

    const handleCloseFilter = () => {
        setShowFilter(false)   
    }
    const resetFilter = () => {
        setFilterLabel("")
        setFilterSlug("")
        setFilter(types)
        initDisplay(types)
        setFilterApplied(false)
    }

    const handleShowCreate = () => {
        setShowCreate(true)
    }

    const handleCreateClose = () => {
        setShowCreate(false)
    }

    const handleCreateDocType = () => {
        console.log(createDocType)
        let dt = new DocTypes(authState.accessToken)
        dt.create(createDocType)
        .then(resp => {
            console.log(resp)
            if(resp.status != 201) {
                alert('unable to create document type')
            } else {
                
                setTypes([...types, resp.data]) 
                setFilter([...filter, resp.data])     
                initDisplay([...filter, resp.data])  
            }
            setShowCreate(false)
        })
        .catch(err => {
            console.log(err)
            alert(err)
        })
    }

    const handleCreateChange = (evt) => {
        const target = evt.target 
        const name = target.name
        const value = target.value 

        setCreateDocType({
            ...createDocType,
            [name]: value   
        })
    }

    const handleShowUpdate = (evt) => {
        console.log(evt.target.id)
        let rec = filter[evt.target.id]
        console.log(rec)
        Object.assign(updateDocType, rec)
        setUpdateDocType(updateDocType)
        setShowUpdate(true)
    }

    const handleUpdateClose = () => {
        setShowUpdate(false)
    }

    const handleUpdateDocType = () => {
        console.log("update doc type")
        console.log(updateDocType)
        setShowUpdate(false)
        let dt = new DocTypes(authState.accessToken)
        dt.update(updateDocType)
        .then(resp => {
            console.log(resp)
            if(resp.status != 200) {
                alert('unable to create document type')
            } else {
                let newTypes = types.map((t, idx) =>{
                    if(t.id == resp.data.id) {
                        return resp.data
                    }
                    return t
                })
                let newFilter = filter.map((t, idx) => {
                    if(t.id == resp.data.id) {
                        return resp.data
                    }
                    return t
                })
                setTypes(newTypes) 
                setFilter(newFilter)     
                initDisplay(newFilter)   
            }
            setShowUpdate(false)
        })
        .catch(err => {
            console.log(err)
            alert(err)
        })
    }

    const handleUpdateChange = (evt) => {
        const target = evt.target 
        const name = target.name
        const value = target.value 

        setUpdateDocType({
            ...updateDocType,
            [name]: value   
        })
    }

    const handleShowRemove = (evt) => {
        console.log(evt.target.id)
        setShowRemove(true)
        let rec = filter[evt.target.id]
        console.log(rec)
        setRemoveDocType(rec.id)
        
    }

    const handleRemoveClose = () => {
        setShowRemove(false)
    }

    const handleRemoveDocType = () => {
        console.log(removeDocType)
        setShowRemove(false)
        let dt = new DocTypes(authState.accessToken)
        dt.remove(removeDocType)
        .then(resp => {
            console.log(resp)
            if(resp.status != 204) {
                alert('unable to create document type')
            } else {
                let newTypes = types.filter(element => element.id != removeDocType)
                let newFilter = filter.filter(element => element.id != removeDocType)
                setTypes(newTypes) 
                setFilter(newFilter)     
                initDisplay(newFilter)   
            }
            setShowRemove(false)
        })
        .catch(err => {
            console.log(err)
            alert(err)
        })
    }
    
    return (
        <div>
            <Navbar bg="light" variant="light">
                <Navbar.Brand href="#home">ECM Document Type Configuration [{envLabel}]: Document Types</Navbar.Brand>
                <Nav className="mr-auto" onSelect={handleNavSelect}>
                <Nav.Link href="#home" eventKey="home">Home</Nav.Link>
                <Nav.Link href="#filter" eventKey="filter">Filter</Nav.Link>
                <Nav.Link href="#reset" eventKey="reset" disabled={filterApplied == false}>Reset</Nav.Link>
                </Nav>
            </Navbar>            
            <div>
            {filter.length == 0 ? 
                (
                    <Button variant="primary" disabled>
                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"/>
                        Loading...
                    </Button>
                )
                : 
                (
                    <div>
                        <Table>
                            <thead>
                                <tr>
                                    <th><Button variant="primary" onClick={handleShowCreate}>Create</Button></th>
                                    {types.length > 0 ? 
                                        Object.keys(types[0]).map((key, index) => {
                                            if(key == "id" || key == "created_at" || key == "updated_at") {
                                                return <th></th>
                                            }
                                            return <th key={key}>{key.toUpperCase().replaceAll('_', ' ')}</th>}
                                        ) : 
                                        <th></th>}                            
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filter.length > 0 ? display.rows.map((t, idx) => 
                                    <tr key={idx}>
                                        <td><Button variant="info" id={idx} onClick={handleShowUpdate}>Update</Button></td>
                                        {Object.keys(t).map((key, index) => {
                                            if(key == "id" || key == "created_at" || key == "updated_at") {
                                                return <td></td>
                                            }
                                            return <td key={index}>{t[key]}</td>
                                        })}
                                        <td><Button variant="danger" id={idx} onClick={handleShowRemove}>X</Button></td>
                                    </tr>) : <tr></tr>}
                            </tbody>
                        </Table>
                        <ButtonGroup>
                            <Button variant="secondary" name="start" disabled={display.nav == false} onClick={handleDisplayNav}>&lt;&lt;</Button>
                            <Button variant="secondary" name="prev" disabled={display.nav == false} onClick={handleDisplayNav}>&lt;</Button>
                            <Button variant="light">{display.curr}</Button>
                            <Button variant="secondary" name="next" disabled={display.nav == false} onClick={handleDisplayNav}>&gt;</Button>
                            <Button variant="secondary" name="last" disabled={display.nav == false} onClick={handleDisplayNav}>&gt;&gt;</Button>
                        </ButtonGroup>

                    </div>
                )
            }
            </div>
            <Modal show={showCreate} onHide={handleCreateClose} backdrop="static" animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Document Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="createBusinessIDs">
                            <Form.Label>Business ID</Form.Label>
                            <Form.Control as="select" name="business_id" onChange={handleCreateChange}>
                                <option key="none" value="none">Select Business ID</option>
                                {businessIDs.map(bsn => <option key={bsn} value={bsn}>{bsn}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="createLabel">
                            <Form.Label>Label</Form.Label>
                            <Form.Control type="text" name="label" onChange={handleCreateChange}/>
                        </Form.Group>
                        <Form.Group controlId="createSlug">
                            <Form.Label>Slug</Form.Label>
                            <Form.Control type="text" name="slug" onChange={handleCreateChange}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCreateClose}>Close</Button>
                    <Button variant="primary" onClick={handleCreateDocType}>Create</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showUpdate} onHide={handleUpdateClose} backdrop="static" animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Document Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="updateBusinessIDs">
                            <Form.Label>Business ID</Form.Label>
                            <Form.Control as="select" name="business_id" defaultValue={updateDocType.business_id} onChange={handleUpdateChange}>
                                {businessIDs.map(bsn => <option key={bsn} value={bsn}>{bsn}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="updateLabel">
                            <Form.Label>Label</Form.Label>
                            <Form.Control type="text" name="label" defaultValue={updateDocType.label} onChange={handleUpdateChange}/>
                        </Form.Group>
                        <Form.Group controlId="updateSlug">
                            <Form.Label>Slug</Form.Label>
                            <Form.Control type="text" name="slug" defaultValue={updateDocType.slug} onChange={handleUpdateChange}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleUpdateClose}>Close</Button>
                    <Button variant="primary" onClick={handleUpdateDocType}>Update</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showRemove} onHide={handleRemoveClose} backdrop="static" animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Document Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>
                        This will remove the document type.
                        <br></br>
                        Would you like to remove it?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleRemoveClose}>No</Button>
                    <Button variant="primary" onClick={handleRemoveDocType}>Yes</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showFilter} onHide={handleCloseFilter} backdrop="static" animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Document Type Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="filterBusinessIDs">
                            <Form.Label>Business ID</Form.Label>
                            <Form.Control as="select" name="business_id" onChange={businessIDChange}>
                                <option key="none" value="none">Select Business ID</option>
                                {businessIDs.map(bsn => <option key={bsn} value={bsn}>{bsn}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="filterLabel">
                            <Form.Label>Label</Form.Label>
                            <Form.Control type="text" name="label" defaultValue={filterLabel} onChange={labelChange}/>
                        </Form.Group>
                        <Form.Group controlId="filterSlug">
                            <Form.Label>Slug</Form.Label>
                            <Form.Control type="text" name="slug" defaultValue={filterSlug} onChange={slugChange}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFilter}>Cancel</Button>
                    <Button variant="primary" onClick={applyFilter}>Apply</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DocumentTypes

