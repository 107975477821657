import React from 'react'

const Health = () => {
    return (
        <div>
            Deployment Tracker config is up and running
        </div>
    )
}

export default Health