import axios from "axios"
import {enviromentConfig} from '../config'

class Permissions {
    constructor(token) {
        this.url = 'https://ecm-document-service-cfg.' + enviromentConfig().environment + '.fdrgcp.com'
        this.token = token
    }

    async list(role) {

        var cfg = {
            method: 'get',
            url: this.url + '/config/v1/permissions',
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + this.token
            }
        }
        if (role) {
            cfg.params = {
                role: role
            }    
        }
        
        const promise =  axios(cfg)

        const dataPromise = promise.then(resp => resp.data)
        return dataPromise
    }

    async create(perm) {
        var cfg = {
            method: 'post',
            url: this.url + '/config/v1/permission',
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + this.token
            },
            data: perm
        }

        const promise =  axios(cfg)
        const respPromise = promise.then(resp => {
            return {
                status: resp.status,
                data: resp.data
            }
        })
        return respPromise
    }

    async update(perm) {
        var cfg = {
            method: 'put',
            url: this.url + '/config/v1/permission/' + perm.id,
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + this.token
            },
            data: perm
        }

        const promise =  axios(cfg)
        const respPromise = promise.then(resp => {
            return {
                status: resp.status,
                data: resp.data
            }
        })
        return respPromise
    }

    async remove(id) {
        var cfg = {
            method: 'delete',
            url: this.url + '/config/v1/permission/' + id,
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + this.token
            },
        }

        const promise =  axios(cfg)
        const respPromise = promise.then(resp => {
            return {
                status: resp.status,
            }
        })
        return respPromise
    }
}

export default Permissions 