import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Home from './components/home';
import Health from './components/health';
import DocumentTypes from './components/doc-types';
import RolePermissions from './components/role-permissions';
import './App.css';
import { Security, LoginCallback } from '@okta/okta-react';
import "bootstrap/dist/css/bootstrap.min.css";
import getOktaConfig from "./okta";

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Security {...getOktaConfig()}>
          <Route path='/' exact={true} component={Home}/>
          <Route path='/implicit/callback' component={LoginCallback}/>
          <Route path='/doc-types' component={DocumentTypes}/>
          <Route path='/role-permissions' component={RolePermissions}/>
          <Route path='/health/readiness' component={Health}/>
          <Route path='/health/check' component={Health}/>
        </Security>
      </BrowserRouter>
    );
  }
}

export default App;
