import {enviromentConfig} from "./config";

const configDev = {
issuer: "https://sso-dev.achieve.com/oauth2/ausakycrhe3ZVs0Wr1d7",
    redirectUri: window.location.origin + '/implicit/callback',
    clientId: "0oa9o6lxpnugSL7Dj1d7",
    postLogoutRedirectUri: window.location.origin,
    pkce: false
};

const configStg = {
    issuer: "https://sso-stg.achieve.com/oauth2/aus9l0njyyrnYxNBx1d7",
    redirectUri: window.location.origin + '/implicit/callback',
    clientId: "0oa9l0juhi5Vk5cf31d7",
    postLogoutRedirectUri: window.location.origin,
    pkce: false
};

const configPrd = {
    issuer: "https://sso.achieve.com/oauth2/aus4vvxolq4TroTka697",
    redirectUri: window.location.origin + '/implicit/callback',
    clientId: "0oa4vyvafeCneoQb5697",
    postLogoutRedirectUri: window.location.origin,
    pkce: false
};

let configMap = {
    dev: configDev,
    stg: configStg,
    prd: configPrd,
};

const getOktaConfig = () => {
    console.log('OKTA ENV', enviromentConfig().environment);

    if (enviromentConfig().environment in configMap) {
        return configMap[enviromentConfig().environment];
    }
    return configDev;
};

export default getOktaConfig;