import axios from "axios"
import {enviromentConfig} from '../config'

class DocTypes {
    constructor(token) {
        this.url = 'https://ecm-document-service-cfg.' + enviromentConfig().environment + '.fdrgcp.com'
        this.token = token
    }

    async list(bus) {

        var cfg = {
            method: 'get',
            url: this.url + '/config/v1/docTypes',
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + this.token
            }
        }
        if (bus) {
            cfg.params = {
                business_ids: bus.join(',')
            }    
        }
        
        const promise =  axios(cfg)

        const dataPromise = promise.then(resp => resp.data)
        return dataPromise
    }

    async create(dt) {
        var cfg = {
            method: 'post',
            url: this.url + '/config/v1/docType',
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + this.token
            },
            data: dt
        }

        const promise =  axios(cfg)
        const respPromise = promise.then(resp => {
            return {
                status: resp.status,
                data: resp.data
            }
        })
        return respPromise
    }

    async update(dt) {
        var cfg = {
            method: 'put',
            url: this.url + '/config/v1/docType/' + dt.id,
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + this.token
            },
            data: dt
        }

        const promise =  axios(cfg)
        const respPromise = promise.then(resp => {
            return {
                status: resp.status,
                data: resp.data
            }
        })
        return respPromise
    }

    async remove(id) {
        var cfg = {
            method: 'delete',
            url: this.url + '/config/v1/docType/' + id,
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + this.token
            },
        }

        const promise =  axios(cfg)
        const respPromise = promise.then(resp => {
            return {
                status: resp.status,
            }
        })
        return respPromise
    }
}

export default DocTypes 