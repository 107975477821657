import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import jwt from 'jwt-decode'
import DocTypes from '../doc-types/docTypes'
import Permissions from '../permissions/permissions'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Spinner from 'react-bootstrap/Spinner'
import {environmentLabel} from '../config'

const RolePermissions = () => {
    const history = useHistory()
    const { authState,  authService } = useOktaAuth()

    const [ types, setTypes ] = useState([])
    const [ businessIDs, setBusinessIDs ] = useState([])
    const [ roles, setRoles ] = useState([])
    const [ docTypes, setDocTypes ] = useState([])
    const [ docTypeFilter, setDocTypeFilter ] = useState([])
    const [ docTypeMap, setDocTypeMap ] = useState({})
    const [ filter, setFilter ] = useState([])
    const [ filterBusinessID, setFilterBusinessID ] = useState("")
    const [ filterRole, setFilterRole ] = useState("")
    const [ filterDocumentType, setFilterDocumentType ] = useState("")
    const [ filterApplied, setFilterApplied ] = useState(false)
    const [ display, setDisplay ] = useState({
        allRows: [],
        rows: [],
        size: 10,
        show: false, 
        nav: false,
        total: -1,
        curr: -1,
    })
    const [ showFilter, setShowFilter ] = useState(false)
    const [ showCreate, setShowCreate ] = useState(false)
    const [ showUpdate, setShowUpdate ] = useState(false)
    const [ showRemove, setShowRemove ] = useState(false)
    const [ updatePermission, setUpdatePermission ] = useState({
        business_id: "",
        role: "",
        document_type: "",
        upload: false,
        view: false,
        download: false,
        edit: false
    })
    const [ removePermission, setRemovePermission ] = useState(-1)
    const [ createPermission, setCreatePermission ] = useState({
        business_id: "",
        role: "",
        document_type: "",
        upload: false,
        view: false,
        download: false,
        edit: false
    })
    const [ envLabel, setEnvLabel ] = useState("N/A")

    const back = async () => {
        history.push('./')
    }

    useEffect(() => {
        if (authState.isAuthenticated == false) {
            alert("User must be authenticated")
            return 
        }

        try {
            let body = jwt(authState.accessToken) 
            console.log(body)
            
            let p = new Permissions(authState.accessToken)
            p.list()
            .then(resp => {
                console.log(resp)
                setTypes(resp)
                initDisplay(resp)
                setFilter(resp)
                setBusinessIDs(body.bsn)
                updateRoles(resp)
            })
            .catch(err => {
                console.log(err)
                alert(err)

            })
            let dt = new DocTypes(authState.accessToken)
            dt.list(body.bsn)
            .then(resp => {
                setDocTypes(resp)
                mapDocTypes(resp)
            })
            .catch(err => {
                console.log(err)
                alert(err)

            })
            setEnvLabel(environmentLabel())

        }catch(err) {
            console.log(err)
            alert(err)
        }
    }, [])

    const mapDocTypes = (dt) => {
        let dtm = {}
        dt.forEach(t => {
            dtm[t.slug] = t.label
        })
        setDocTypeMap(dtm)
    }
    const initDisplay = (filter) => {
        let d = {
            allRows: filter,
            rows: [],
            size: 10,
            show: false, 
            total: filter.length,
            curr: 1,
            nav: false
        }

        if (filter.length > d.size) {
            d.rows = filter.slice(0, d.size)
            d.show = true 
            d.nav = true
        } else {
            d.rows = filter
        }
        console.log(filter)
        console.log(d)
        setDisplay(d)
    }

    const handleDisplayNav = (evt) => {
        if (display.nav == false) {
            return 
        }

        displayNav(evt.target.name)
    }

    const displayNav = (action) => {

        let next = 0
        let end = 0

        switch (action) {
        case "start":
            display.rows = display.allRows.slice(0, display.size)
            display.curr = 1
            break
        case "next":
            if((display.curr * display.size) >= display.total) {
                return
            }
            next = display.curr * display.size 
            end = next + display.size 
            if (end > display.total) {
                end = display.total
            }
            display.rows = display.allRows.slice(next, end)
            display.curr = display.curr + 1
            if((display.curr * display.size) >= display.total) {
                display.curr -=1
            }
            break
        case "prev":
            if(display.curr == 1) {
                return
            }
            display.curr -= 1
            next = (display.curr - 1) * display.size 
            end = next + display.size 
            if (end > display.total) {
                end = display.total
            }
            display.rows = display.allRows.slice(next, end)
            break
        case "last":
            next = Math.floor(display.total / display.size)
            if((display.total % display.size) == 0) {
                next--   
            }
            display.curr = next 
            next = display.curr * display.size 
            end = next + display.size 
            if (end > display.total) {
                end = display.total
            }
            display.rows = display.allRows.slice(next, end)
            break
        default:
            alert(action)
            return
        }
        setDisplay({...display})
    }

    const updateRoles = (permissions) => {
        let sr = new Set()
        permissions.forEach(element => {
            sr.add(element.role)
        });
        setRoles(Array.from(sr))
    }

    const businessIDChange = (evt) => {
        setFilterBusinessID(evt.target.value)
    }

    const roleChange = (evt) => {
        setFilterRole(evt.target.value)
    }

    const documentTypeChange = (evt) => {
        setFilterDocumentType(evt.target.value)
    }

    const applyFilter = () => {
        let f = []
        types.forEach(t => {
            if (filterBusinessID.length > 0 && filterBusinessID != t.business_id) {
                return
            }
            if (filterRole.length > 0 && filterRole != t.role) {
                return
            }
            if (filterDocumentType.length > 0 && filterDocumentType != t.document_type) {
                return
            }
            f.push(t)
        })
        console.log(f)
        setFilter(f)
        initDisplay(f)
        setShowFilter(false)
        setFilterApplied(true)
    }

    const resetFilter = () => {
        setFilterBusinessID("")
        setFilterRole("")
        setFilterDocumentType("")
        setFilter(types)
        initDisplay(types)
        setFilterApplied(false)
    }

    const handleShowCreate = () => {
        setShowCreate(true)
    }

    const handleCreateClose = () => {
        setShowCreate(false)
        setDocTypeFilter([])
    }

    const handleCreatePremission = () => {
        console.log(createPermission)
        let permissions = new Permissions(authState.accessToken)
        permissions.create(createPermission)        
        .then(resp => {
            console.log(resp)
            if(resp.status != 201) {
                alert('unable to cresate permissions')
            } else {
                setTypes([...types, resp.data]) 
                setFilter([...filter, resp.data])   
                initDisplay([...filter, resp.data]) 
                handleCreateClose() 
            }
        })
        .catch(err => {
            console.log(err)
            alert(err)
        })
    }

    const handleCreateChange = (evt) => {
        const target = evt.target 
        const name = target.name
        const value = target.type === "checkbox" ? target.checked :target.value 

        createPermission[name] = value
        if(name == "business_id") {
            businessDocTypes(value) 
            setCreatePermission(createPermission)  
        }
        console.log(createPermission)
    }

    const businessDocTypes = (bsn) => {
        let f = []
        docTypes.forEach(dt => {
            console.log(dt)
            if (bsn != dt.business_id) {
                return
            }
            f.push(dt)
        })
        setDocTypeFilter(f)
    }

    const handleShowUpdate = (evt) => {
        let rec = display.rows[evt.target.id]
        Object.assign(updatePermission, rec)
        console.log(updatePermission)
        businessDocTypes(updatePermission.business_id)
        setUpdatePermission(updatePermission)
        setShowUpdate(true)
    }

    const handleUpdateChange = (evt) => {
        const target = evt.target 
        const name = target.name
        const value = target.type === "checkbox" ? target.checked :target.value 

        updatePermission[name] = value
        if(name == "business_id") {
            businessDocTypes(value)   
            setUpdatePermission(updatePermission)
        }
        console.log(updatePermission)
    }

    const handleUpdateClose = () => {
        setShowUpdate(false)
        setDocTypeFilter([])
    }

    const handleUpdatePermission = () => {
        console.log(updatePermission)
        let permissions = new Permissions(authState.accessToken)
        permissions.update(updatePermission)
        .then(resp => {
            console.log(resp)
            if(resp.status != 200) {
                alert('unable to create document type')
            } else {
                let newTypes = types.map((t, idx) =>{
                    if(t.id == resp.data.id) {
                        return resp.data
                    }
                    return t
                })
                let newFilter = filter.map((t, idx) => {
                    if(t.id == resp.data.id) {
                        return resp.data
                    }
                    return t
                })
                setTypes(newTypes) 
                setFilter(newFilter)  
                initDisplay(newFilter)   
                handleUpdateClose()
            }
        })
        .catch(err => {
            console.log(err)
            alert(err)
        })
    }

    const handleShowRemove = (evt) => {
        console.log(evt.target.id)
        let rec = display.rows[evt.target.id]
        console.log(rec)
        setRemovePermission(rec.id)
        setShowRemove(true)
    }

    const handleRemoveClose = () => {
        setShowRemove(false)
    }

    const handleRemovePermission = () => {
        console.log(removePermission)
        let permissions = new Permissions(authState.accessToken)
        permissions.remove(removePermission)
        .then(resp => {
            console.log(resp)
            if(resp.status != 204) {
                alert('unable to create document type')
            } else {
                let newTypes = types.filter(element => element.id != removePermission)
                let newFilter = filter.filter(element => element.id != removePermission)
                setTypes(newTypes) 
                setFilter(newFilter)  
                initDisplay(newFilter)  
                handleRemoveClose()
            }
        })
        .catch(err => {
            console.log(err)
            alert(err)
        })
    }

    const handleNavSelect = (evt) => {
        switch(evt){
        case "home":
            back()
            return 
        case "filter":
            setShowFilter(true)
            return
        case "reset":
            resetFilter()
            return
        default:
            alert(evt)
        }
    }

    const handleCloseFilter = () => {
        setShowFilter(false)   
    }


    return (
        <div>
            <Navbar bg="light" variant="light">
                <Navbar.Brand href="#home">ECM Document Type Configuration [{envLabel}]: Role Permissions</Navbar.Brand>
                <Nav className="mr-auto" onSelect={handleNavSelect}>
                <Nav.Link href="#home" eventKey="home">Home</Nav.Link>
                <Nav.Link href="#filter" eventKey="filter">Filter</Nav.Link>
                <Nav.Link href="#reset" eventKey="reset" disabled={filterApplied == false}>Reset</Nav.Link>
                </Nav>
            </Navbar>            
            <div>
                {filter.length == 0 ? 
                (
                    <Button variant="primary" disabled>
                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"/>
                        Loading...
                    </Button>
                )
                : 
                (
                    <div>
                        <Table>
                            <thead>
                                <tr>
                                    <th><Button variant="primary" onClick={handleShowCreate}>Create</Button></th>
                                    {types.length > 0 ? 
                                        Object.keys(types[0]).map((key, index) => {
                                            if(key == "id" || key == "created_at" || key == "updated_at") {
                                                return <th></th>
                                            }
                                            return <th key={key}>{key.toUpperCase().replaceAll('_', ' ')}</th>}
                                        ) : 
                                        <th></th>}                            
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filter.length > 0 ? display.rows.map((t, idx) => 
                                    <tr key={idx}>
                                        <td><Button variant="info" id={idx} onClick={handleShowUpdate}>Update</Button></td>
                                        {Object.keys(t).map((key, index) => {
                                            switch (key) {
                                            case "id":
                                            case "created_at":
                                            case "updated_at":
                                                return <td></td>
                                            case "document_type":
                                                return <td key={index}>{docTypeMap[t[key]]}</td>
                                            case "upload":
                                            case "view":
                                            case "download":
                                            case "edit":
                                                return <td key={index}><input readOnly={true} type="checkbox" checked={t[key]}/></td>
                                            default:
                                                return <td key={index}>{t[key]}</td>
                                            }
                                        })}
                                        <td><Button variant="danger" id={idx} onClick={handleShowRemove}>X</Button></td>
                                    </tr>) : <tr></tr>}
                            </tbody>
                        </Table>
                        <ButtonGroup>
                            <Button variant="secondary" name="start" disabled={display.nav == false} onClick={handleDisplayNav}>&lt;&lt;</Button>
                            <Button variant="secondary" name="prev" disabled={display.nav == false} onClick={handleDisplayNav}>&lt;</Button>
                            <Button variant="light">{display.curr}</Button>
                            <Button variant="secondary" name="next" disabled={display.nav == false} onClick={handleDisplayNav}>&gt;</Button>
                            <Button variant="secondary" name="last" disabled={display.nav == false} onClick={handleDisplayNav}>&gt;&gt;</Button>
                        </ButtonGroup>
                    </div>        
                )}
            </div>
            <Modal show={showCreate} onHide={handleCreateClose} backdrop="static" animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Document Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="createBusinessIDs">
                            <Form.Label>Business ID</Form.Label>
                            <Form.Control as="select" name="business_id" onChange={handleCreateChange}>
                                <option key="none" value="none">Select Business ID</option>
                                {businessIDs.map(bsn => <option key={bsn} value={bsn}>{bsn}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="createRole">
                            <Form.Label>Role</Form.Label>
                            <Form.Control type="text" name="role" onChange={handleCreateChange}/>
                        </Form.Group>
                        <Form.Group controlId="createDocType">
                            <Form.Label>Document Type</Form.Label>
                            <Form.Control as="select" name="document_type" onChange={handleCreateChange}>
                                <option key="none" value="none">Select Document Type</option>
                                {docTypeFilter.map(dt => <option key={dt.slug} value={dt.slug}>{dt.label}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="createUpload">
                            <Form.Check type="checkbox" label="Upload" name="upload" onChange={handleCreateChange}/>
                        </Form.Group>
                        <Form.Group controlId="createView">
                            <Form.Check type="checkbox" label="View" name="view" onChange={handleCreateChange}/>
                        </Form.Group>
                        <Form.Group controlId="createDownload">
                            <Form.Check type="checkbox" label="Download" name="download" onChange={handleCreateChange}/>
                        </Form.Group>
                        <Form.Group controlId="createEdit">
                            <Form.Check type="checkbox" label="Edit" name="edit" onChange={handleCreateChange}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCreateClose}>Close</Button>
                    <Button variant="primary" onClick={handleCreatePremission}>Create</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showUpdate} onHide={handleUpdateClose} backdrop="static" animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Document Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                        <Form.Group controlId="updateBusinessIDs">
                            <Form.Label>Business ID</Form.Label>
                            <Form.Control as="select" name="business_id" defaultValue={updatePermission.business_id} onChange={handleUpdateChange}>
                                {businessIDs.map(bsn => <option key={bsn} value={bsn}>{bsn}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="updateRole">
                            <Form.Label>Role</Form.Label>
                            <Form.Control type="text" name="role" defaultValue={updatePermission.role} onChange={handleUpdateChange}/>
                        </Form.Group>
                        <Form.Group controlId="updateDocType">
                            <Form.Label>Document Type</Form.Label>
                            <Form.Control as="select" name="document_type" defaultValue={updatePermission.document_type} onChange={handleUpdateChange}>
                            {docTypeFilter.map(dt => <option key={dt.slug} value={dt.slug}>{dt.label}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="updateUpload">
                            <Form.Check type="checkbox" label="Upload" name="upload" defaultChecked={updatePermission.upload} onChange={handleUpdateChange}/>
                        </Form.Group>
                        <Form.Group controlId="updateView">
                            <Form.Check type="checkbox" label="View" name="view" defaultChecked={updatePermission.view} onChange={handleUpdateChange}/>
                        </Form.Group>
                        <Form.Group controlId="updateDownload">
                            <Form.Check type="checkbox" label="Download" name="download" defaultChecked={updatePermission.download} onChange={handleUpdateChange}/>
                        </Form.Group>
                        <Form.Group controlId="updateEdit">
                            <Form.Check type="checkbox" label="Edit" name="edit" defaultChecked={updatePermission.edit} onChange={handleUpdateChange}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleUpdateClose}>Close</Button>
                    <Button variant="primary" onClick={handleUpdatePermission}>Update</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showRemove} onHide={handleRemoveClose} backdrop="static" animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Document Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>
                        This will remove the Permission Role.
                        <br></br>
                        Would you like to remove it?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleRemoveClose}>No</Button>
                    <Button variant="primary" onClick={handleRemovePermission}>Yes</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showFilter} onHide={handleCloseFilter} backdrop="static" animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Role Permissions Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="filterBusinessIDs">
                            <Form.Label>Business ID</Form.Label>
                            <Form.Control as="select" name="business_id" onChange={businessIDChange}>
                                <option key="none" value="none">Select Business ID</option>
                                {businessIDs.map(bsn => <option key={bsn} value={bsn}>{bsn}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="filterRoles">
                            <Form.Label>Role</Form.Label>
                            <Form.Control as="select" name="roles" onChange={roleChange}>
                                <option key="none" value="none">Select Role</option>
                                {roles.map(role => <option key={role} value={role}>{role}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="filterDocType">
                            <Form.Label>Document Type</Form.Label>
                            <Form.Control as="select" name="document_type" onChange={documentTypeChange}>
                                <option key="none" value="none">Select Document Type</option>
                                {docTypeFilter.map(dt => <option key={dt.slug} value={dt.slug}>{dt.label}</option>)}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFilter}>Cancel</Button>
                    <Button variant="primary" onClick={applyFilter}>Apply</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

}

export default RolePermissions