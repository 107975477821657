import React, { useState, useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import {environmentLabel} from '../config'

const Home = () => {
    const { authState,  authService } = useOktaAuth()
    const history = useHistory()
    const [ envLabel, setEnvLabel ] = useState("N/A")

    useEffect(() => {
        setEnvLabel(environmentLabel())
    }, [])

    const login = async () => {
        authService.login('/')
    }
    
    const logout = async () => {
        authService.logout('/')
    }

    const docType = async () => {
        history.push('./doc-types')
    }

    const rolePermissions = async () => {
        history.push('./role-permissions')
    }

    const handleNavSelect = (evt) => {
        switch(evt){
        case "login":
            login()
            return    
        case "docType":
            docType()
            return    
        case "rolePermissions":
            rolePermissions()
            return    
        case "logout":
            logout()
            return    
        default:
        }
        alert(evt)
    }

    return (
        <div>
            <Navbar bg="light" variant="light">
                <Navbar.Brand href="#home">ECM Document Type Configuration [{envLabel}]</Navbar.Brand>
                { !authState.isAuthenticated ? 
                    (
                        <Nav className="mr-auto" onSelect={handleNavSelect}>
                            <Nav.Link href="#login" eventKey="login">Login</Nav.Link>
                        </Nav>
                    )
                : (
                    <Nav className="mr-auto" onSelect={handleNavSelect}>
                        <Nav.Link href="#docType" eventKey="docType">Document Types</Nav.Link>
                        <Nav.Link href="#rolePermissions" eventKey="rolePermissions">Role Permissions</Nav.Link>
                        <Nav.Link href="#logout" eventKey="logout">Logout</Nav.Link>
                    </Nav>
                 )
                }
            </Navbar>            
            <p>
                Welcome to the ECM Service Configuration application.
                <br></br>
                This application can be used to manage business's permissions and document types.
            </p>
        </div>
    )

}

export default Home