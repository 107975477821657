
export const enviromentConfig = () => {
    if(window.location.origin.includes('prd')) {
        return {
            environment: 'prd'
        }
    }  
    if(window.location.origin.includes('stg')) {
        return {
            environment: 'stg'
        }
    }  
    return {
        environment: 'dev'
    }
}

export const environmentLabel = () => {
    if(window.location.origin.includes('prd')) {
        return 'Production'
    }  
    if(window.location.origin.includes('stg')) {
        return 'Staging'
    }  
    return 'Development'
}